<template>
<div class="home">
<h1 class="title" data-aos="fade-left" data-aos-duration="910" >{{$t('services-page.home.title')}}</h1>
</div> 
<div class="body">
<div class="freight-management-div box" data-aos="fade-left">
        <p class="freight-shipping  service" >{{$t('services-page.body.freight-management-divs[0].service')}}</p >
      
        <div class="content">
        
        <img class="icon1" alt="" src="../assets/rectangle-13@2x.png" />
        <div class="txt">
        
          <p class="storage-and-transportation">{{$t('services-page.body.freight-management-divs[0].content[0]')}}
</p>
          <p class="optimization-of-resources-to-e">{{$t('services-page.body.freight-management-divs[0].content[1]')}}
</p>
          <p class="professional-road-surveys-for">{{$t('services-page.body.freight-management-divs[0].content[2]')}}
</p>
          
        
        </div>
        
        </div>
  </div>
  <div class="freight-management-div box" data-aos="fade-left">
        <p class="fleet-management service">{{$t('services-page.body.freight-management-divs[1].service')}}</p>
      
        <div class="content">
        
        <img class="icon1" alt="" src="../assets/rectangle-12@2x.png"/>
        <div class="txt">
        
          <p class="driver-assessment-and-training">{{$t('services-page.body.freight-management-divs[1].content[0]')}}
</p>          <p class="driver-assessment-and-training">{{$t('services-page.body.freight-management-divs[1].content[1]')}}
</p>
            <p class="analyzes-vehicle-repair-times">{{$t('services-page.body.freight-management-divs[1].content[2]')}} </p>
           
        
        </div>
        
        </div>
  </div>
  
   <div class="freight-management-div box" data-aos="fade-left">
        
          <p class="safety-health-and-environment service">{{$t('services-page.body.freight-management-divs[2].service')}}</p>
<div class="content">
        <img class="icon1" alt="" src="../assets/rectangle-16@2x.png"/>
        <div class="txt">
          <p class="perform-she-audits-and-develop">{{$t('services-page.body.freight-management-divs[2].content[0]')}}
</p>
        
        </div>

</div>
      </div>


      <div class="freight-management-div box" data-aos="fade-left">
        
        <p class="safety-health-and-environment service">{{$t('services-page.body.freight-management-divs[3].service')}}</p>
<div class="content">
      <img class="icon1" alt=""   src="../assets/logisticsTraining.webp"/>
      <div class="txt">
        <p class="perform-she-audits-and-develop">{{$t('services-page.body.freight-management-divs[3].content[0]')}}
</p>
      
      </div>

</div>
    </div>
      

</div>

    <div class="how" data-aos="fade-left" >
        <h1>{{$t('services-page.body.how.title')}}</h1>
        <div class="HWDI">
          <div class="one">
            <p>{{$t('services-page.body.how.steps[0]')}}</p>
          </div>
          <div class="two">
            <p>{{$t('services-page.body.how.steps[1]')}}</p>
          </div>
          <div class="three">
            <p>{{$t('services-page.body.how.steps[2]')}}</p>
          </div>
          <div class="four">
            <p>{{$t('services-page.body.how.steps[3]')}}</p>
          </div>
          <div class="five">
            <p>{{$t('services-page.body.how.steps[4]')}}</p>
          </div>
        </div>
      </div>
   

</template>
<script>
import { onMounted } from '@vue/runtime-core';
import AOS from "aos";
export default {
    components:{
    },
    setup() {

        onMounted(() => {
    AOS.init();
})
    },
}
</script>

<style scoped>
.HWDI{
    margin:auto;
display:grid;
grid-template-columns: repeat(3,300px);
grid-template-rows: repeat(3,250px);
grid-column-gap: 20px;
grid-row-gap: 20px;

}
.HWDI>div{
background:rgba(247, 247, 247, 0.788);
border-radius: 5px;
color:rgba(24, 24, 24, 0.856);
display:flex;
align-items:center;
justify-content: center;
padding:10px;
}

.HWDI>div>p{
text-align: center;
margin:auto;
font-size:18px;
text-transform: capitalize;
font-weight: 400;

}
.one{

}
.how{
height:120vh;
margin:auto;
display:flex;
width:100vw;

flex-direction: column;
background: #E9EEF1;

}
.how>h1{
font-size: 50px;
text-transform: uppercase;
letter-spacing: 4px;
}
.service{
font-size: 25px;
font-weight: 600;
width: fit-content;
margin: auto;
position: relative;
text-transform: uppercase;
}
.service::after{
content: "";
position:absolute;
width: 100%;
height:2px;
bottom:0px;
left:0;
background-color: #FF9F0F;

}
.body{
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap:30px;
height: 250vh;
padding: 50px;
}

.freight-management-div{
margin: 20px 0 10px 0;
padding: 35px;
width:50%;
box-shadow:  6px 8px 19px #b8b8b8,
             -6px -8px 19px #ffffff;

}
.icon1{
width:200px;
height:200px;
}
.content{
display: flex;
align-items: center;
justify-content: space-around;
gap:30px;


}
.txt{
font-size: 18px;
text-align: justify;
font-weight: 400;
margin-bottom: 15px ;

}
.txt>*{
width: 90%;
text-align: left;
}
.home{
background:url("../assets/rectangle-30@2x.webp") center center;
background-attachment: scroll;


height:50vh;
width:100vw;

display: flex;
justify-content: center;
align-items: center;
}
.title{
text-transform: uppercase;
color: white;
font-size: 45px;
position:relative;
}
.title::after{
content: "";
position: absolute;
background:#FF9F0F;
width:100%;
height: 3px;
bottom: 0;
left:0;
}
.box{
width:70%
}
@media screen and (max-width: 1300px) {

.freight-management-div{
margin: 20px 0 10px 0;
padding: 30px;
width:100%;
box-shadow:  6px 8px 19px #b8b8b8,
             -6px -8px 19px #ffffff;
}
.body{
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap:30px;
width: 80%;
margin: auto;
height:240vh;
}
.icon1{
width:200px;
height:200px;
}
.txt{
text-align: justify;
font-weight: 300;
margin-bottom: 12px ;
}
.txt>*{
width: 90%;
text-transform: capitalize;
font-size: 16px;
}
.content{
display: flex;
align-items: center;
justify-content: center;
gap:50px;
margin-top: 20px;
}
.service{
font-size: 25px;
font-weight: 500;
width: fit-content;
margin: auto;
position: relative;
text-transform: uppercase;
}
.service::after{
content: "";
position:absolute;
width: 100%;
height:3px;
bottom:0px;
left:0;
background-color: #FF9F0F;

}
.title{
text-transform: uppercase;
color: white;
font-size: 50px;
position:relative;
}
.title::after{
content: "";
position: absolute;
background:#FF9F0F;
width:100%;
height: 10px;
bottom: 0;
left:0;
}


}
@media screen and (max-width: 770px) {
    .HWDI{
        margin:auto;
    display:grid;
    grid-template-columns: repeat(2,320px);
    grid-template-rows: repeat(3,250px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    
    }
    .HWDI>div{
    background:rgba(247, 247, 247, 0.788);
    border-radius: 5px;
    color:rgba(24, 24, 24, 0.856);
    display:flex;
    align-items:center;
    justify-content: center;
    padding:10px;
    }
    
    .HWDI>div>p{
    text-align: center;
    margin:auto;
    font-size:18px;
    text-transform: capitalize;
    font-weight: 400;
    
    }
.freight-management-div{
margin: 20px 0 10px 0;
padding: 30px;
width:100%;
box-shadow:  6px 8px 19px #b8b8b8,
             -6px -8px 19px #ffffff;
}

.icon1{
width:200px;
height:200px;
}
.txt{
text-align: justify;
font-weight: 300;
margin-bottom: 12px ;
}
.txt>*{
width: 90%;
text-transform: capitalize;
font-size: 16px;
}
.content{
display: flex;
align-items: center;
justify-content: center;
gap:50px;
margin-top: 20px;
}
.how{
    height:200vh;
    margin:auto;
    display:flex;
    width:100vw;
    
    flex-direction: column;
    background: #E9EEF1;
    
    }
.service{
font-size: 25px;
font-weight: 500;
width: fit-content;
margin: auto;
position: relative;
text-transform: uppercase;
}
.service::after{
content: "";
position:absolute;
width: 100%;
height:3px;
bottom:0px;
left:0;
background-color: #FF9F0F;

}
.title{
text-transform: uppercase;
color: white;
font-size: 50px;
position:relative;
}
.title::after{
content: "";
position: absolute;
background:#FF9F0F;
width:100%;
height: 10px;
bottom: 0;
left:0;
}


}
@media screen and (min-width: 320px) and (max-width: 767px) {
    .body{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:30px;
        width: 80%;
        margin: auto;
        height:290vh;
        }
    .how{
        height:200vh;
        margin:auto;
        display:flex;
        width:100vw;
        flex-direction: column;
        background: #E9EEF1;
        
        }
        .how>h1{
        font-size:35px;
        }
    .HWDI{
        margin:auto;
    display:grid;
    grid-template-columns: repeat(1,250px);
    grid-template-rows: repeat(5,200px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    
    }
    .HWDI>div{
    background:rgba(247, 247, 247, 0.788);
    border-radius: 5px;
    color:rgba(24, 24, 24, 0.856);
    display:flex;
    align-items:center;
    justify-content: center;
    padding:10px;
    }
    
    .HWDI>div>p{
    text-align: center;
    margin:auto;
    font-size:18px;
    text-transform: capitalize;
    font-weight: 400;
    
    }
.freight-management-div{
margin: 20px 0 10px 0;
padding: 30px;
width:100%;
box-shadow:  6px 8px 19px #b8b8b8,
             -6px -8px 19px #ffffff;
}

.icon1{
width:250px;
height:180px;
}
.txt{
text-align: justify;
font-weight: 400;
margin-bottom: 10px ;
}
.txt>*{
width: 98%;
text-transform: capitalize;
font-size: 15px;
}
.content{
display: flex;
align-items: center;
justify-content: space-between;
gap:15px;
margin-top: 20px;
flex-direction: column;
}
.service{
font-size: 20px;
font-weight: 500;
width: fit-content;
margin: auto;
position: relative;
text-transform: uppercase;
}
.service::after{
content: "";
position:absolute;
width: 100%;
height:3px;
bottom:0px;
left:0;
background-color: #FF9F0F;

}
.title{
text-transform: uppercase;
color: white;
font-size: 50px;
position:relative;
}
.title::after{
content: "";
position: absolute;
background:#FF9F0F;
width:100%;
height: 10px;
bottom: 0;
left:0;
}
}
</style>
