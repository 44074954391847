<template>

        <div class="container">
            <div class="left-panel">
                <div class="overlay"></div>
                <h1>{{$t('partnerShip-Page.left-side')}}</h1>
            </div>
            <div class="right-panel">
                <form  id="myForm" @submit.prevent="submitForm" @keypress.enter="submitForm">
                    <label for="fullname">{{$t('partnerShip-Page.fields[0].title')}}</label>
                    <input type="text" id="fullname" :placeholder="$t('partnerShip-Page.fields[0].placeholder')"      v-model="state.name" name="name">
                    <span class="error"  v-if="v$.name.$error">  {{ v$.name.$errors[0].$message }} </span>
                    
                    
                    <label for="email">{{$t('partnerShip-Page.fields[1].title')}}</label>
                    <input type="email" id="email" :placeholder="$t('partnerShip-Page.fields[1].placeholder')"   v-model="state.email" name="email">
                    <span class="error"  v-if="v$.email.$error">  {{ v$.email.$errors[0].$message }} </span>
                    
                    <label for="phone">{{$t('partnerShip-Page.fields[2].title')}}</label>
                    <input type="tel" id="phone" :placeholder="$t('partnerShip-Page.fields[2].placeholder')"   v-model="state.telephone" name="telephone">
                    <span class="error"  v-if="v$.telephone.$error">  {{ v$.telephone.$errors[0].$message }} </span>
                    
                    <label for="location">{{$t('partnerShip-Page.fields[3].title')}}</label>
                    <input type="text" id="location" :placeholder="$t('partnerShip-Page.fields[3].placeholder')"   v-model="state.location" name="location">
                    <span class="error"  v-if="v$.location.$error">  {{ v$.location.$errors[0].$message }} </span>

                    <label for="Activities">{{$t('partnerShip-Page.fields[4].title')}}</label>
                    <input type="text" id="Activities" :placeholder="$t('partnerShip-Page.fields[4].placeholder')"   v-model="state.activities" name="activities">
                    <span class="error"  v-if="v$.activities.$error">  {{ v$.name.$errors[0].$message }} </span>

                    <label for="message">{{$t('partnerShip-Page.fields[5].title')}}</label>
                    <textarea  id="message" :placeholder="$t('partnerShip-Page.fields[5].placeholder')"   v-model="state.message" name="message"> </textarea>
                    <span class="error"  v-if="v$.message.$error">  {{ v$.message.$errors[0].$message }} </span>
                    
                    <p>{{$t('partnerShip-Page.fields[6].title')}}?</p>
                    <div class="business-size">
                        <label>
                            <input type="radio" v-model="state.employees" value="1-50" name="employees"> 1-50
                        </label>
                        <label>
                            <input type="radio"  v-model="state.employees" value="51-150" name="employees"> 51-150
                        </label>
                        <label>
                            <input type="radio"  v-model="state.employees" value="151-500" name="employees"> 151-500
                        </label>
                        <label>
                            <input type="radio"  v-model="state.employees" value="500+" name="employees"> 500+
                        </label>

                       <span class="error"  v-if="v$.employees.$error">  {{ v$.employees.$errors[0].$message }} </span>

                    </div>
                    
                    <button type="submit" class="submit-btn">{{$t('partnerShip-Page.submit')}}</button>
                </form>
            </div>
        </div>
   
    
</template>

<script>
import emailjs from "@emailjs/browser";
import {ref,reactive,computed} from "vue"
import useVuelidate from '@vuelidate/core'
import { required,email,minLength,helpers} from '@vuelidate/validators'

export default{

    setup(){
        const state=reactive({
            name:"",
            email:"",
            employees:null,
            telephone:"",
            location:"",
            activities:"",
            message:"",
        })

        const phoneValidator = helpers.withMessage(
  'Invalid phone number',
  value => {
    // Example pattern: (123) 456-7890 or 123-456-7890
    const phonePattern = /^(\+?[1-9]{1,4}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?[\d\s.-]{5,15}$/;
    return phonePattern.test(value);
  }
);
const phoneNumberValidator=helpers.regex(/^(\+?[1-9]{1,4}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?[\d\s.-]{5,15}$/)
        const rules=computed(()=>{
            return {
                name :{required},

                email:{required,email},
                employees:{required},
                telephone:{required,phoneNumberValidator},
                location:{required},
                activities:{required,minlength:minLength(1)},
                message:{required, minLength:minLength(20) }

            }
        })

        const v$= useVuelidate(rules,state)

        function sendEmail(){
            console.log(state)
                   
                   emailjs.sendForm('service_x0vrhv2', 'template_sfdbbod','#myForm',
                    'polQPG6B-ulsOOaj4')
                     .then((result) => {
                         alert('Email Sent!', result.text);
                     }, (error) => {
                        console.log(error)
                        console.log('email was not sent', error.text);

                         alert('email was not sent', error.text);
             
                     });}
        return{state,v$,sendEmail}
    },
    methods:{
        submitForm(){
            console.log(this.state)
            this.v$.$validate()
             if(!this.v$.$error){
                this.sendEmail()
               

                console.log("no problem")
             }
             else{
                console.log(this.v$)
                 console.log("problem when submiting")
             }

                
            }

    }
}


</script>

<style  scoped>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f3f4f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
}

.error{
    color: #085c6e;
        margin-top: 10px;
    font-weight: 600;
    
    }   
.container {
    margin:auto;
    width: 100%;
    max-width: 1200px;
    display: flex;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-top: 40px;
}

.left-panel{
    background:url("../assets/partners.jpg" ) center center ;
    background-attachement:contain;
    z-index:1;
    position:relative;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

.left-panel, .right-panel {
    padding: 40px;
    flex: 1;
}

.left-panel {
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.left-panel h1 {
    font-size: 28px;
    color: #ffffff;
    margin-bottom: 10px;
    z-index:1;
}



.right-panel {
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
    align-self:flex-start;
}

input {
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.business-size {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.business-size label {
    display: flex;
    align-items: center;
}

.business-size input {
    margin-right: 5px;
}

.submit-btn {
    background-color: #1a0770;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition :.3s all ease-in-out;
}

.submit-btn:hover {
    background-color: #07084b;
}

/* Responsive Design */
@media (max-width: 768px) {
    header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    nav ul {
        flex-direction: column;
        margin-top: 10px;
    }
    
    .container {
        flex-direction: column;
    }
}

</style>