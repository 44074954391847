import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import contact from '../views/contactVue.vue'
import expertise from '../views/expertiseView.vue'
import Services from '../views/ServicesView.vue'
import benefit from "../views/ourBenefit.vue"
import training from "../views/TrainingView.vue"
import partner from "../views/partner.vue"
import Policies from "../views/Policies.vue"

//polcies
import AssetAndInventoryManagementPolicy from '../views/policy/Asset and Inventory Management Policy.vue'
import confidentialityAndDataProtectionPolicy from '@/views/policy/confidentiality and data protection policy.vue'
import EnvironmentalManagementPolicy from '@/views/policy/Environmental Management Policy.vue'
import FinancialManagementPolicy from '../views/policy/FinancialManagementPolicy.vue'
import HRPolicy from '@/views/policy/HR Policy.vue'
import MalpracticePolicy from '@/views/policy/Malpractice Policy.vue'
import moniteringPolicy from '@/views/policy/moniteringPolicy.vue'
import PartnershipPolicy from "@/views/policy/Partnership Policy.vue"
import GovernancePolicy from '@/views/policy/Governance  Policy.vue'
import ProcurementManagementPolicy from '@/views/policy/Procurement Management Policy.vue'
import RiskManagementPolicy from '@/views/policy/Risk Management Policy.vue'
import ProgrammaticAccountabilityPolicy from '@/views/policy/Programmatic Accountability Policy.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home | HLC',
      description: 'HLC: Pioneering Advanced Logistics Solutions in Cameroon for Enhanced Safety and Operational Efficiency'}
    
  },
  ,{
    path: '/services',
    name :'services',
    component: Services,
    meta: {
      title: 'Our services | HLC',
      description: 'Discover HLC\'s comprehensive logistics services, including fleet management, freight forwarding, personnel training, and warehousing & transportation. Your one-stop solution for logistics excellence.'}
  },
  {
    path: '/expertise',
    name: 'expertise',
    component: expertise,
    meta: {
      title: 'Our Expertise | HLC',
      description: 'Explore HLC Logistic Consultancy\'s industry expertise. We provide valuable insights into fleet management, freight forwarding, and logistics. Stay informed with our expert knowledge.'}
  },
  
  {
    path:'/contact',
    name:'contact',
    component:contact,
    meta: {
      title: 'Contact Us | Get in Touch with HLC Logistic Consultancy',
      description: 'Ready to optimize your logistics operations? Contact HLC Logistic Consultancy today. Reach out for expert guidance in fleet management, freight forwarding, and more.'}
  },
  {
    path:'/ourBenefits',
    name:'ourBenefit',
    component:benefit,
    meta: {
      title: 'Your Benefits | HLC',
      description: 'Learn about the benefits of choosing HLC Logistic Consultancy. We enhance your logistics operations for efficiency, reliability, and growth. Explore the advantages we offer.'}
  },
  {
    path:'/partner',
    name:'partner',
    component:partner,
    meta: {
      title: 'partner with us | HLC',
      description: 'Learn about the benefits of choosing HLC Logistic Consultancy. We enhance your logistics operations for efficiency, reliability, and growth. Explore the advantages we offer.'}
  },
  {
    path:'/companyPolicies',
    component:Policies,
    name:'companyPolicies',
    meta: {
      title: 'Company Policies | HLC',
      description: 'Learn about the benefits of choosing HLC Logistic Consultancy. We enhance your logistics operations for efficiency, reliability, and growth. Explore the advantages we offer.'}
  
  },
      {
        path:"/AssetAndInventoryManagementPolicy",
        name:"AssetAndInventoryManagementPolicy",
        component:AssetAndInventoryManagementPolicy
      },
      {
        path:"/confidentialityAndDataProtectionPolicy",
        name:"confidentialityAndDataProtectionPolicy",
        component:confidentialityAndDataProtectionPolicy
      },
      {
        path:"/EnvironmentalManagementPolicy",
        name:"EnvironmentalManagementPolicy",
        component:EnvironmentalManagementPolicy
      },
      {
        path:"/FinancialManagementPolicy",
        name:"FinancialManagementPolicy",
        component:FinancialManagementPolicy,
      },
      {
        path:"/MalpracticePolicy",
        name:"MalpracticePolicy",
        component:MalpracticePolicy
      },
      {
        path:"/moniteringPolicy",
        name:"moniteringPolicy",
        component:moniteringPolicy
      },
      {
        path:"/HRPolicy",
        name:"HRPolicy",
        component:HRPolicy
      },
      {
        path:"/PartnershipPolicy",
        name:"PartnershipPolicy",
        component:PartnershipPolicy
      },
      {
        path:"/GovernancePolicy",
        name:"GovernancePolicy",
        component:GovernancePolicy
      },
      {
        path:"/ProcurementManagementPolicy",
        name:"ProcurementManagementPolicy",
        component:ProcurementManagementPolicy
      },
      {
        path:"/RiskManagementPolicy",
        name:"RiskManagementPolicy",
        component:RiskManagementPolicy
      },
      {
        path:"/ProgrammaticAccountabilityPolicy",
        name:"ProgrammaticAccountabilityPolicy",
        component:ProgrammaticAccountabilityPolicy
      },
      
      
    
  {
    path:'/training',
    name:'training',
    component:training,
    meta: {
      title: 'Training programs | HLC',
      description: 'Learn about the benefits of choosing HLC Logistic Consultancy. We enhance your logistics operations for efficiency, reliability, and growth. Explore the advantages we offer.'}
  },{
    path: '/:catchAll(.*)',
    redirect:'/' // Redirect to your main route or any default route
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to the top of the page on navigation
    return { top: 0 }
  }
})


router.beforeEach((to, from, next) => {
  // Update the title and description when navigating to a new route
  document.title = to.meta.title || 'HLC | Your Logistics Partner for Success';
  const descriptionMetaTag = document.querySelector('meta[name="description"]');
  if (descriptionMetaTag) {
    descriptionMetaTag.setAttribute('content', to.meta.description || '');
  }
  next();
});

export default router
