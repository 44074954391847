<template>
    <div class="home">
        <h1 class="title" data-aos="fade-left">{{$t('contact-page.home.title')}}</h1>
     </div>
     <div class="body">
        <h2 class="get">{{$t('contact-page.body.get')}}</h2>
        <div class="get_form">
           <div class="form">
              <p class="send">{{$t('contact-page.body.get_form.form.send')}}</p>
              <form @submit.prevent="submitForm" @keypress.enter="submitForm" id="myform">
                 <div class="tex">
                    <div class="name">
                       <label for="name">{{$t('contact-page.body.get_form.form.tex.name.label')}} </label>
                       <input type="text" id="name" name="name" class="username in" :placeholder="$t('contact-page.body.get_form.form.tex.name.placeholder')" v-model="state.name">
                       <span class="error"  v-if="v$.name.$error"> name {{ v$.name.$errors[0].$message }} </span>
                    </div>
                    <div class="email">
                       <label for="email">{{$t('contact-page.body.get_form.form.tex.email.label')}} </label>
                       <input type="email" id="email" name="email" class="email in" :placeholder="$t('contact-page.body.get_form.form.tex.email.placeholder')" v-model="state.email">
                       <span class="error" v-if="v$.email.$error">  {{ v$.email.$errors[0].$message }} </span>
                    </div>
                 </div>
                 <div class="message">
                    <label for="message">{{$t('contact-page.body.get_form.form.message.label')}}:</label>
                    <textarea name="message" class="in" :placeholder="$t('contact-page.body.get_form.form.message.placeholder')" v-model="state.message"></textarea>
                    <span class="error" v-if="v$.message.$error"> {{ v$.message.$errors[0].$message }} </span>
                 </div>
                 <input type="submit" :value="$t('contact-page.body.get_form.form.submit')">
              </form>
           </div>
           <div class="credentials">
              <h2>{{$t('contact-page.body.get_form.credentials.h2')}}</h2>
              <div class="information">
                 <p><Icon icon="material-symbols:phone-in-talk" /> "+237 674-646-019"</p>
                 <p class="credentials_email"><Icon icon="material-symbols:mail-rounded" /> hozalogisticsconsultants@gmail.com"</p>
              </div>
           </div>
        </div>
     </div>
     
     
</template>

<script>



import { onMounted } from '@vue/runtime-core'
import emailjs from "@emailjs/browser";
import {ref,reactive,computed} from "vue"
import useVuelidate from '@vuelidate/core'
import { required,email,minLength} from '@vuelidate/validators'
import { Icon } from '@iconify/vue';



export default {
    components:{

        Icon
    },

    setup() {

      

       
const state=reactive({
    name:'',
    email:' ',
    message: ''
})
             const rules=computed(()=>{
                    return{

                name:{required},
                email:{required,email},
                message:{required, minLength:minLength(20) }

        }
           })

            
        const v$ = useVuelidate(rules, state)  
     
        
                //   if(e.value==" "){
                //       e.value.classList.add('red')
                      
                //   }
                //   else if(e.value.classList.contains("red")&&e.value!=" "){
                //       e.value.classList.remove('red')
                //   }
                
              
              function sendEmail(){
                   
                  emailjs.sendForm('service_x0vrhv2', 'template_6ucaqca','#myform','polQPG6B-ulsOOaj4'
                    )
                    .then((result) => {
                        alert('Email Sent!', response.status, response.text);

                    }, (error) => {
                        console.log('email was not sent', error.text);

            
                    });
                   

              }
 return {
    state,
    v$,
    sendEmail
    
 }
 },
 methods: {
      submitForm(){
            this.v$.$validate()
             if(!this.v$.$error){
                this.sendEmail()
               

                console.log("no problem")
             }
             else{
                 console.log("problem when submiting")
             }

                
            }

           
 },

}
</script>
<style scoped>
.body{
height:130vh;
position:relative;
}
.get_form{
display:flex;
align-items: center;
justify-content: space-between;
width: 80%;
margin: auto;
height: 90vh;
background: #FFFEFE;
border: 1px solid #878484;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
margin-top: 30px;
background: url("../assets/circle-scatter-haikei\ \(3\).svg") no-repeat ;
    background-size: cover;
}
.get_form>*{
height: 100%;
}
textarea{
resize: none;
width: 580px;
font-size: 16px;
border:none;
text-transform: capitalize;
text-align:justify;
font-family: "poppins";
line-height: 1.9;
border-bottom: 1px solid black;
background: none;

}
.send{
font-size: 22px;
font-weight: 300;
text-transform: capitalize;
position: relative;
}
.error{
color: #085c6e;
    margin-top: 10px;
font-weight: 600;

}
.send::after{
content: '';
position: absolute;
width: 100%;
height: 3px;
background: #e29522;
bottom: 0;
left: 0;
margin: auto;
}
textarea::placeholder{
font-family:"poppins";
margin-left: 30px;
text-align: center;
text-transform: capitalize;
}
input{
border-bottom: 1px solid black;
width: 250px;
height: 35px;
text-align: center;
font-size: 16px;
border-top-style: hidden;
border-right-style: hidden;
border-left-style: hidden;
border-bottom-style: groove;
background: none;

}
input::placeholder{
font-family:"poppins";
margin-left: 30px;
text-align: center;
text-transform: capitalize;
}
.form{
    
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
margin: auto;
}
form{
display: flex;
gap:20px;
align-items: center;
justify-content: center;
flex-direction: column;
margin: auto;
}
.get{
font-size: 40px;
text-transform: capitalize;
position: relative;
width:20%;
margin: auto;
}
.name,.email,.message{
    display:flex;
    flex-direction:column ;
}
.name>label,.email>label,.message>label{
    align-self: flex-start;
    font-weight: 300;
    text-transform: capitalize;
}
.get::after{
content: '';
position: absolute;
width: 100%;
height: 3px;
background: #e2a522;
bottom: 0;
left: 0;

}
textarea:focus, input:focus{
    outline: none;
}
.information{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
margin: auto;
height: 80%;
}
.information>p{
font-weight: 600;
text-transform: capitalize;
}

.tex{
display: flex;
gap: 30px;
margin-bottom: 40px;
}
.home{
display: flex;
align-items: center;
justify-content: center;
width:100vw;
height:50vh;
background: url("../assets/rectangle-36@2x.webp") center center;
background-attachment: fixed;
background-size: cover;
display: flex;
margin-bottom: 40px;
}
.title{
color: white;
font-size: 80px;
text-transform: uppercase;
position: relative;
}
.title::after{
content:'';
position: absolute;
bottom: 0;
left: 0;
width:100%;
height: 5px;
background: #e29522;
}

.credentials{
width: 35%;
align-self: flex-end;
justify-self:flex-end;

background: #405D69;
color: #fff;
box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.25);
}
.credentials>h2{
font-weight: 400;
font-size: 22px;
text-transform: capitalize;
}
input[type="submit"]{
background: #e2b522;
color: #fff;
font-weight: 500;
font-size: 18px;
padding:15px;
align-self: flex-end;
text-transform: uppercase;
width: 200px;
height: 55px;
border-radius: 0;
border: none;
}
*:-webkit-autofill,
*:-webkit-autofill:hover,
*:-webkit-autofill:focus,
*:-webkit-autofill:active {
    /* use animation hack, if you have hard styled input */
    transition: all 5000s ease-in-out 0s;
    transition-property: background-color, color;
    /* if input has one color, and didn't have bg-image use shadow */
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    /* text color */
    -webkit-text-fill-color: rgb(0, 0, 0);
    /* font weigth */
    font-weight: 300!important;
}

@media screen and (max-width: 1300px) {

    .body{
        height:200vh;
        position:relative;
        }
    .get_form{
        display:flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: auto;
        height: 120vh;
        background: #FFFEFE;
        border: 1px solid #878484;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-top: 30px;
        background: url("../assets/circle-scatter-haikei\ \(3\).svg") no-repeat ;
            background-size: cover;
        }
        .get_form>*{
        height: 100%;
        }
        textarea{
            border-radius: none;
        resize: none;
        width: 650px;
        font-size: 16px;
        border:none;
        height:100px;
        text-transform: capitalize;
        text-align:justify;
        font-family: "poppins";
        line-height: 1.7;
        border-bottom: 1px solid black;
        background: none;
        border-top-style: none;
        border-right-style: none;
        border-left-style: none;
        border-bottom-style: groove;
        
        }
        .send{
        font-size: 22px;
        font-weight: 300;
        text-transform: capitalize;
        position: relative;
        }
        .error{
        color: #085c6e;
            margin-top: 10px;
        font-weight: 600;
        
        }
        .send::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: #e2af22;
        bottom: 0;
        left: 0;
        margin: auto;
        }
        textarea::placeholder{
        font-family:"poppins";
        margin-left: 30px;
        text-align: center;
        text-transform: capitalize;
        }
        input{
        border-bottom: 2px solid black;
        width: 250px;
        height: 35px;
        text-align: center;
        font-size: 16px;
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
        border-bottom-style: groove;
        background: none;
        
        }
        input::placeholder{
        font-family:"poppins";
        margin-left: 30px;
        text-align: center;
        text-transform: capitalize;
        }
        .form{
            
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        }
        form{
        display: flex;
        gap:20px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        }
        .get{
        font-size: 40px;
        text-transform: capitalize;
        position: relative;
        width:30%;
        margin: auto;
        }
        .name,.email,.message{
            display:flex;
            flex-direction:column ;
        }
        .name>label,.email>label,.message>label{
            align-self: flex-start;
            font-weight: 300;
            text-transform: capitalize;
        }
        .get::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: #dfae0f;
        bottom: 0;
        left: 0;
        
        }
        textarea:focus, input:focus{
            outline: none;
        }
        .information{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        height: 80%;
        }
        .information>p{
        font-weight: 600;
        text-transform: capitalize;
        }
        
        .tex{
        display: flex;
        gap: 30px;
        margin-bottom: 40px;
        }
        .home{
        display: flex;
        align-items: center;
        justify-content: center;
        width:100vw;
        height:50vh;
        background-attachment: fixed;
        background-size: cover;
        display: flex;
        margin-bottom: 40px;
        }
        .title{
        color: white;
        font-size: 40px;
        text-transform: uppercase;
        position: relative;
        }
        .title::after{
        content:'';
        position: absolute;
        bottom: 0;
        left: 0;
        width:100%;
        height: 2px;
        background: #e4b210;
        }
        
      
        .credentials>h2{
        font-weight: 400;
        font-size: 22px;
        text-transform: capitalize;
        }
        input[type="submit"]{
        background: hsla(41, 93%, 49%, 0.897);
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        padding:15px;
        align-self: flex-end;
        text-transform: uppercase;
        width: 200px;
        height: 55px;
        border-radius: 0;
        border: none;
        }
        *:-webkit-autofill,
        *:-webkit-autofill:hover,
        *:-webkit-autofill:focus,
        *:-webkit-autofill:active {
            /* use animation hack, if you have hard styled input */
            transition: all 5000s ease-in-out 0s;
            transition-property: background-color, color;
            /* if input has one color, and didn't have bg-image use shadow */
            -webkit-box-shadow: 0 0 0 1000px #fff inset;
            /* text color */
            -webkit-text-fill-color: rgb(0, 0, 0);
            /* font weigth */
            font-weight: 300!important;
        }
}

@media screen and (max-width: 870px) {
    .body{
        height:200vh;
        position:relative;
        }
    .get_form{
        display:flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: auto;
        height: 120vh;
        background: #FFFEFE;
        border: 1px solid #878484;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-top: 30px;
        background: url("../assets/circle-scatter-haikei\ \(3\).svg") no-repeat ;
            background-size: cover;
        }
        .get_form>*{
        height: 100%;
        }
        textarea{
            border-radius: none;
        resize: none;
        width: 650px;
        font-size: 16px;
        border:none;
        height:100px;
        text-transform: capitalize;
        text-align:justify;
        font-family: "poppins";
        line-height: 1.7;
        border-bottom: 1px solid black;
        background: none;
        border-top-style: none;
        border-right-style: none;
        border-left-style: none;
        border-bottom-style: groove;
        
        }
        .send{
        font-size: 22px;
        font-weight: 300;
        text-transform: capitalize;
        position: relative;
        }
        .error{
        color: #085c6e;
            margin-top: 10px;
        font-weight: 600;
        
        }
        .send::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: #e2af22;
        bottom: 0;
        left: 0;
        margin: auto;
        }
        textarea::placeholder{
        font-family:"poppins";
        margin-left: 30px;
        text-align: center;
        text-transform: capitalize;
        }
        input{
        border-bottom: 2px solid black;
        width: 250px;
        height: 35px;
        text-align: center;
        font-size: 16px;
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
        border-bottom-style: groove;
        background: none;
        
        }
        input::placeholder{
        font-family:"poppins";
        margin-left: 30px;
        text-align: center;
        text-transform: capitalize;
        }
        .form{
            
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        }
        form{
        display: flex;
        gap:20px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        }
        .get{
        font-size: 40px;
        text-transform: capitalize;
        position: relative;
        width:30%;
        margin: auto;
        }
        .name,.email,.message{
            display:flex;
            flex-direction:column ;
        }
        .name>label,.email>label,.message>label{
            align-self: flex-start;
            font-weight: 300;
            text-transform: capitalize;
        }
        .get::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: #dfae0f;
        bottom: 0;
        left: 0;
        
        }
        textarea:focus, input:focus{
            outline: none;
        }
        .information{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        height: 80%;
        }
        .information>p{
        font-weight: 600;
        text-transform: capitalize;
        }
        
        .tex{
        display: flex;
        gap: 30px;
        margin-bottom: 40px;
        }
        .home{
        display: flex;
        align-items: center;
        justify-content: center;
        width:100vw;
        height:50vh;
        background-attachment: fixed;
        background-size: cover;
        display: flex;
        margin-bottom: 40px;
        }
        .title{
        color: white;
        font-size: 40px;
        text-transform: uppercase;
        position: relative;
        }
        .title::after{
        content:'';
        position: absolute;
        bottom: 0;
        left: 0;
        width:100%;
        height: 2px;
        background: #e4b210;
        }
        
        .credentials{
       display:none;
        }
        .credentials>h2{
        font-weight: 400;
        font-size: 22px;
        text-transform: capitalize;
        }
        input[type="submit"]{
        background: hsla(41, 93%, 49%, 0.897);
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        padding:15px;
        align-self: flex-end;
        text-transform: uppercase;
        width: 200px;
        height: 55px;
        border-radius: 0;
        border: none;
        }
        *:-webkit-autofill,
        *:-webkit-autofill:hover,
        *:-webkit-autofill:focus,
        *:-webkit-autofill:active {
            /* use animation hack, if you have hard styled input */
            transition: all 5000s ease-in-out 0s;
            transition-property: background-color, color;
            /* if input has one color, and didn't have bg-image use shadow */
            -webkit-box-shadow: 0 0 0 1000px #fff inset;
            /* text color */
            -webkit-text-fill-color: rgb(0, 0, 0);
            /* font weigth */
            font-weight: 300!important;
        }
}
@media screen and (max-width: 730px) {
    .body{
        height:120vh;
        position:relative;
        }
    .get_form{
        display:flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: auto;
        height: 120vh;
        background: #FFFEFE;
        border: 1px solid #878484;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-top: 30px;
        background: url("../assets/circle-scatter-haikei\ \(3\).svg") no-repeat ;
            background-size: cover;
        }
        .get_form>*{
        height: 100%;
        }
        textarea{
            border-radius: none;
        resize: none;
        width: 450px;
        font-size: 16px;
        border:none;
        height:80px;
        text-transform: capitalize;
        text-align:justify;
        font-family: "poppins";
        line-height: 1.7;
        border-bottom: 1px solid black;
        background: none;
        border-top-style: none;
        border-right-style: none;
        border-left-style: none;
        border-bottom-style: groove;
        
        }
        .send{
        font-size: 22px;
        font-weight: 300;
        text-transform: capitalize;
        position: relative;
        }
        .error{
        color: #085c6e;
            margin-top: 10px;
        font-weight: 600;
        
        }
        .send::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: #e2af22;
        bottom: 0;
        left: 0;
        margin: auto;
        }
        textarea::placeholder{
        font-family:"poppins";
        margin-left: 30px;
        text-align: center;
        text-transform: capitalize;
        }
        input{
        border-bottom: 2px solid black;
        width: 250px;
        height: 35px;
        text-align: center;
        font-size: 16px;
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
        border-bottom-style: groove;
        background: none;
        
        }
        input::placeholder{
        font-family:"poppins";
        margin-left: 30px;
        text-align: center;
        text-transform: capitalize;
        }
        .form{
            
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        }
        form{
        display: flex;
        gap:20px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        }
        .get{
        font-size: 40px;
        text-transform: capitalize;
        position: relative;
        width:30%;
        margin: auto;
        }
        .name,.email,.message{
            display:flex;
            flex-direction:column ;
        }
        .name>label,.email>label,.message>label{
            align-self: flex-start;
            font-weight: 300;
            text-transform: capitalize;
        }
        .get::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: #dfae0f;
        bottom: 0;
        left: 0;
        
        }
        textarea:focus, input:focus{
            outline: none;
        }
        .information{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        height: 80%;
        }
        .information>p{
        font-weight: 600;
        text-transform: capitalize;
        }
        
        .tex{
        display: flex;
        gap: 30px;
        margin-bottom: 40px;
        }
        .home{
        display: flex;
        align-items: center;
        justify-content: center;
        width:100vw;
        height:50vh;
        background-attachment: fixed;
        background-size: cover;
        display: flex;
        margin-bottom: 40px;
        }
        .title{
        color: white;
        font-size: 40px;
        text-transform: uppercase;
        position: relative;
        }
        .title::after{
        content:'';
        position: absolute;
        bottom: 0;
        left: 0;
        width:100%;
        height: 2px;
        background: #e4b210;
        }
        
        .credentials{
       display:none;
        }
        .credentials>h2{
        font-weight: 400;
        font-size: 22px;
        text-transform: capitalize;
        }
        input[type="submit"]{
        background: hsla(41, 93%, 49%, 0.897);
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        padding:15px;
        align-self: flex-end;
        text-transform: uppercase;
        width: 200px;
        height: 55px;
        border-radius: 0;
        border: none;
        }
        *:-webkit-autofill,
        *:-webkit-autofill:hover,
        *:-webkit-autofill:focus,
        *:-webkit-autofill:active {
            /* use animation hack, if you have hard styled input */
            transition: all 5000s ease-in-out 0s;
            transition-property: background-color, color;
            /* if input has one color, and didn't have bg-image use shadow */
            -webkit-box-shadow: 0 0 0 1000px #fff inset;
            /* text color */
            -webkit-text-fill-color: rgb(0, 0, 0);
            /* font weigth */
            font-weight: 300!important;
        }
}
@media screen and (max-width: 530px) {
    .get_form{
        display:flex;
        align-items: center;
        justify-content: space-between;
        width: 97%;
        margin: auto;
        height: 75vh;
        background: #FFFEFE;
        border: 1px solid #878484;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-top: 30px;
        background: url("../assets/circle-scatter-haikei\ \(3\).svg") no-repeat ;
            background-size: cover;
        }
        .get_form>*{
        height: 100%;
        }
        textarea{
        resize: none;
        width: 350px;
        font-size: 16px;
        border:none;
        text-transform: capitalize;
        text-align:justify;
        font-family: "poppins";
        line-height: 1.9;
        border-bottom: 1px solid black;
        background: none;
        
        }
        .send{
        font-size: 22px;
        font-weight: 300;
        text-transform: capitalize;
        position: relative;
        }
        .error{
        color: #085c6e;
            margin-top: 10px;
        font-weight: 600;
        
        }
        .send::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: #e2a522;
        bottom: 0;
        left: 0;
        margin: auto;
        }
        textarea::placeholder{
        font-family:"poppins";
        margin-left: 30px;
        text-align: center;
        text-transform: capitalize;
        }
        input{
        border-bottom: 1px solid black;
        width: 290px;
        height: 35px;
        text-align: center;
        font-size: 16px;
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
        border-bottom-style: groove;
        background: none;
        
        }
        input::placeholder{
        font-family:"poppins";
        margin-left: 30px;
        text-align: center;
        text-transform: capitalize;
        }
        .form{
            
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        }
        form{
        display: flex;
        gap:20px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        }
        .get{
        font-size: 30px;
        text-transform: capitalize;
        position: relative;
        width:50%;
        margin: auto;
        }
        .name,.email,.message{
            display:flex;
            flex-direction:column ;
        }
        .name>label,.email>label,.message>label{
            align-self: flex-start;
            font-weight: 300;
            text-transform: capitalize;
        }
        .get::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: #e2b522;
        bottom: 0;
        left: 0;
        
        }
        textarea:focus, input:focus{
            outline: none;
        }
        .information{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        height: 80%;
        }
        .information>p{
        font-weight: 600;
        text-transform: capitalize;
        }
        
        .tex{
        display: flex;
        gap: 30px;
            flex-direction: column;
        margin-bottom: 40px;
        }
        .home{
        display: flex;
        align-items: center;
        justify-content: center;
        width:100vw;
        height:50vh;
        background-attachment: fixed;
        background-size: cover;
        display: flex;
        margin-bottom: 40px;
        }
        .title{
        color: white;
        font-size: 40px;
        text-transform: uppercase;
        position: relative;
        }
        .title::after{
        content:'';
        position: absolute;
        bottom: 0;
        left: 0;
        width:100%;
        height: 2px;
        background: #e6b515;
        }
        
    
       
        input[type="submit"]{
        background: #db9c14;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        padding:10px;
        align-self: flex-end;
        text-transform: uppercase;
        width: 150px;
        height: 50px;
        border-radius: 0;
        border: none;
        }
        *:-webkit-autofill,
        *:-webkit-autofill:hover,
        *:-webkit-autofill:focus,
        *:-webkit-autofill:active {
            /* use animation hack, if you have hard styled input */
            transition: all 5000s ease-in-out 0s;
            transition-property: background-color, color;
            /* if input has one color, and didn't have bg-image use shadow */
            -webkit-box-shadow: 0 0 0 1000px #fff inset;
            /* text color */
            -webkit-text-fill-color: rgb(0, 0, 0);
            /* font weigth */
            font-weight: 300!important;
        }

}
</style>