<template>
    <div class="footer"> 
        <div class="important">
        
        <div class="contact_info">
        <div class="title">{{$t('footer.important.contact_info.title')}}</div>
        <p class="message">{{$t('footer.important.contact_info.message')}}</p>
        <div class="contact">
        <div class="telephone con">
    
        <p> <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" class="phone" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M15 12h2a5 5 0 0 0-5-5v2a3 3 0 0 1 3 3m4 0h2c0-5-4.03-9-9-9v2c3.86 0 7 3.13 7 7m1 3.5c-1.25 0-2.45-.2-3.57-.57c-.35-.11-.74-.03-1.02.25l-2.2 2.2a15.097 15.097 0 0 1-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02A11.36 11.36 0 0 1 8.5 4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1a17 17 0 0 0 17 17a1 1 0 0 0 1-1v-3.5a1 1 0 0 0-1-1Z"/></svg> +237674646019</p></div>
    
        <div class=" con">
        <p class="email">  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"  class="mail" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5l-8-5V6l8 5l8-5v2z"/></svg>  hozalogisticsconsultants@gmail.com</p></div>
    
        <div class="position con"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" class="map" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7Z"/></svg> Douala Cameroon</div>
        
        </div>
        </div>
        <div class="useful_links">
        <div class="title">{{$t('footer.useful_links.title')}}</div>
        <router-link to="/" >{{$t('nav.links[0].text')}}</router-link>
        <router-link to="/training" >{{$t('nav.links[1].text')}}</router-link>
        <router-link to="/expertise" >{{$t('nav.links[2].text')}}</router-link>
        <router-link to="/services" >{{$t('nav.links[3].text')}}</router-link>
        <router-link to="/ourBenefits">{{$t('nav.links[4].text')}}</router-link>
        <router-link to="/companyPolicies" >{{$t('nav.links[7].text')}}</router-link>
        <router-link to="/contact">{{$t('nav.links[5].text')}}</router-link>
        <router-link to="/contact">{{$t('nav.links[6].text')}}</router-link>

        </div>
        
       <div class="socials">
        <div class="title">Social</div>
        <div class="social-links">
            <div class="linked_in"> <a target="_blank" href=" https://www.linkedin.com/in/ste-hoza-logistique-conseil-1087a0322"><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M17.04 17.043h-2.962v-4.64c0-1.107-.023-2.531-1.544-2.531c-1.544 0-1.78 1.204-1.78 2.449v4.722H7.793V7.5h2.844v1.3h.039c.397-.75 1.364-1.54 2.808-1.54c3.001 0 3.556 1.974 3.556 4.545v5.238ZM4.447 6.194c-.954 0-1.72-.771-1.72-1.72s.767-1.72 1.72-1.72a1.72 1.72 0 0 1 0 3.44Zm1.484 10.85h-2.97V7.5h2.97v9.543ZM18.521 0H1.476C.66 0 0 .645 0 1.44v17.12C0 19.355.66 20 1.476 20h17.042c.815 0 1.482-.644 1.482-1.44V1.44C20 .646 19.333 0 18.518 0h.003Z"/></svg></a> </div>
            <div class="linked_in"> <a href="https://wa.me/658156519"><svg xmlns="http://www.w3.org/2000/svg"  width="2em" height="2em" viewBox="0,0,256,256"
                style="fill:#000000;">
                <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(8.53333,8.53333)"><path d="M15,3c-6.627,0 -12,5.373 -12,12c0,2.25121 0.63234,4.35007 1.71094,6.15039l-1.60352,5.84961l5.97461,-1.56836c1.74732,0.99342 3.76446,1.56836 5.91797,1.56836c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12zM10.89258,9.40234c0.195,0 0.39536,-0.00119 0.56836,0.00781c0.214,0.005 0.44692,0.02067 0.66992,0.51367c0.265,0.586 0.84202,2.05608 0.91602,2.20508c0.074,0.149 0.12644,0.32453 0.02344,0.51953c-0.098,0.2 -0.14897,0.32105 -0.29297,0.49805c-0.149,0.172 -0.31227,0.38563 -0.44727,0.51563c-0.149,0.149 -0.30286,0.31238 -0.13086,0.60938c0.172,0.297 0.76934,1.27064 1.65234,2.05664c1.135,1.014 2.09263,1.32561 2.39063,1.47461c0.298,0.149 0.47058,0.12578 0.64258,-0.07422c0.177,-0.195 0.74336,-0.86411 0.94336,-1.16211c0.195,-0.298 0.39406,-0.24644 0.66406,-0.14844c0.274,0.098 1.7352,0.8178 2.0332,0.9668c0.298,0.149 0.49336,0.22275 0.56836,0.34375c0.077,0.125 0.07708,0.72006 -0.16992,1.41406c-0.247,0.693 -1.45991,1.36316 -2.00391,1.41016c-0.549,0.051 -1.06136,0.24677 -3.56836,-0.74023c-3.024,-1.191 -4.93108,-4.28828 -5.08008,-4.48828c-0.149,-0.195 -1.21094,-1.61031 -1.21094,-3.07031c0,-1.465 0.76811,-2.18247 1.03711,-2.48047c0.274,-0.298 0.59492,-0.37109 0.79492,-0.37109z"></path></g></g>
                </svg></a> </div>
        </div>
       
        
    </div>
        
        
        </div>
        <div class="copyright">
        <h3>&#169; HozaLogisticsConsultancy <span class="year">{{year}}</span></h3>
        </div>
        </div>
      
    
</template>
<script>
import { Icon } from '@iconify/vue';
import {ref} from 'vue';
export default {
    name:"footer",
  components:{Icon},
    setup() {
        let year=ref("")

        let date=new Date().getUTCFullYear()
        year=date
        return{year}
    },
}
</script>
<style scoped>


.footer{
background:#141d38;
height: 450px;
color:white;
display:flex;
align-items: center;
justify-content: center;
gap:60px;
width:100%;
flex-direction: column;
position: relative;
}
.important{
display:flex;
align-items: center;
justify-content: center;
gap:100px
}
.useful_links{
display: flex;
flex-direction: column;

}.copyright{
color: #405D69;
    font-size: 13px;
    transition: .3s all ease-in-out;
}
.copyright:hover{
color: white;
}
p,a{
text-align: left;
}
div>p{
margin: 40px 0 40px 0;

}
.contact>*{
font-size: 13px;
color: #fff ;

}
.social-links{
    display: flex;
}
svg{
    
}
.position{
text-align: left;
transition: .3s all ease-in-out;
}
.map{
transition: .3s all ease-in-out;
}
.position:hover{
color:white;}
.position:hover>svg{
color:rgb(223, 37, 37);
}
.email{
text-align: left;
transition: .3s all ease-in-out;
}
.mail{
transition: .3s all ease-in-out;
}
.email:hover{
color:white;}
.email:hover>.mail{
color:rgba(23, 170, 228, 0.808);
}
.message{
font-size: 13px;
width: 300px;
}
.telephone{
transition: .3s all ease-in-out;
}
.telephone:hover{
color: rgba(23, 170, 228, 0.808);}
a{
margin: 10px 0 10px 0;
text-decoration: none;
color:#fff;
position:relative;
transition:.3s all ease-in-out;
font-size: 13px;
}
a::before{
content:">";
margin-right: 10px;
}

a::after{
content:"";
position:absolute;
width:0;
height:2px;
background: #cfdde0;
transition:.3s all ease-in-out;
top:17.5px;
left:0;

}
a:hover{
color:white;
}
a:hover::after{
width:40%;
}
.title{
font-size: 18px;
font-weight: 500;
}
.linkedin{
width:20px;
height:20px;
}
.linked_in:hover{
color: #fff;
}

.linked_in>a::before,.linked_in>a::after{
    content: " ";
    width:0;
}
@media screen and (max-width:800px) {
.footer{
background:#141d38;
height: 1000px;
color:white;
display:flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap:100px;
width:100%;
position: relative;
}
.important{

 

color:white;
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap:80px;
margin: 80px 0 0 0;   
}

}

</style>
