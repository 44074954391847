<template>
 
  <navBar></navBar>

  
  <router-view >
  </router-view>
  <foot/>
</template>
<script>
import {ref} from 'vue';
import foot from "./components/footer.vue";
import navBar from "./components/navBar.vue";
import { onMounted } from '@vue/runtime-core'
import AOS from "aos";
export default {
components:{foot,navBar},
  setup() {
             
   
         
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');
body{
margin:0;
}

#app {
  font-family: poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  overflow-x: hidden;
}

nav {
  position:fixed;
  display: flex;
  gap: 80px;
  align-items: center;
  justify-content: space-evenly;
  background: rgba(240, 240, 240, 0.8);
  width: 100%;
  height:70px;

  

padding: 10px;
}

.links{
  position:fixed;

display: flex;
align-items: center;
justify-content: center;
gap:40px;
}
img{
width: 60px;
height: 60px;
}
nav{
position:relative;
}

.links {
  font-weight:400;
  color:black;
  text-decoration: none;
  align-self: center;
position: relative;
transition: .3s all ease-in-out;
font-size:15px ;

}
.fade-enter-from,
.fade-leave-to{
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active{
  transition: opacity 0.3s ease-out;
}
.links a::after{
content:'';
position: absolute;
width: 0;
height: 2px;
background:#fcbc0d;
bottom: 0;
margin-top:3px ;
left: 0;
transition: .3s all ease-in-out;

}
.links a:hover::after{
content:'';
position: absolute;
width: 100%;
height: 2px;
background:#fcbc0d;
bottom: 0;
left: 0;
}
.line{
width: 50px;
background:#000;
height: 5px;
border-radius: 10px;
position:relative;
display: none;
}
.line::after,.line::before{
content: "";
width: 50px;
background:#000;
height: 5px;
border-radius: 10px;
position: absolute;
}
.line::after{

top: 10px;
left: 0;
}
.line::before{

bottom: 10px;
left: 0;
}
.img-navlinks::after{
  content: none !important;
  
  width: 0;
  height: 0;
  background:#ffffff;
}
.img-navlinks:hover::after{
  content: none !important;
  width: 0;
  height: 0;
background:#ffffff;

}

.sideLinks{
height: 500px;
position:relative;
display: none;
transition: .5s all ease-in-out;
}
nav a.router-link-exact-active {
  color: rgba(10, 45, 97, 0.829);
  font-weight:600 ;
}

.contact-us{
  color:white;
  background-color: rgba(10, 45, 97, 0.829);
  padding:10px;
}
.contact-us:visited{
  color:white;
}
@media screen and (max-width: 780px) {
  .line_two{
    border-radius: 3px;
    width:40px;
    height:40px;
  position: relative;
  }
  .line_two::before,.line_two::after{
  
    content:'';
    position:absolute;
    width:36px;
    height:4px;
    background-color:rgb(15, 15, 15);
    border-radius:4px;
    top:16px;
    box-shadow:0 0 2px 0 #ccc;
  }
  .line_two::before{
    
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
    left:2px;
  }
  .line_two::after{
    
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
    right:2px;

  }
.links{
display: none;
}
.line{

display: block;
transition: .5s all ease-in-out;
}

.sideLinks{
  position:absolute;
  display: flex;
  right: -50000px;
flex-direction: column;
align-items: center;
justify-content: center;
gap:40px;
width: 0;
padding: 10px;
height: 180vw;
background: #fff;
transition: .5s all ease-in-out;

}
.linkside{
  font-weight: 500;
  color:black;
  text-decoration: none;
  align-self: flex-start;
  
position: relative;
top:-250px;
transition: .5s all ease-in-out;

}
.linkside a::after{
content:'';
position: absolute;
width: 0;
height: 4px;
background:#e2af22;
bottom: 0;
margin-top:3px ;
left: 0;
transition: 1.3s all ease-in-out;

}
.linkside a:hover::after{
content:'';
position: absolute;
width: 100%;
height: 4px;
background:#e2b522;
bottom: 0;
left: 0;
}
.linko{
position:relative;
right: 0;
top: 0;
display: flex;
width:60%;
transition: .5s all ease-in-out;
z-index: 3;
position: fixed;
}

}
@media screen and (max-width: 490px) {
  .line_two{
    border-radius: 3px;
    width:40px;
    height:40px;
  position: relative;
  }
  .line_two::before,.line_two::after{
  
    content:'';
    position:absolute;
    width:36px;
    height:4px;
    background-color:rgb(15, 15, 15);
    border-radius:4px;
    top:16px;
    box-shadow:0 0 2px 0 #ccc;
  }
  .line_two::before{
    
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
    left:2px;
  }
  .line_two::after{
    
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
    right:2px;

  }
.links{
display: none;
}
.line{

display: block;
transition: .5s all ease-in-out;
}

.sideLinks{
  position:absolute;
  display: flex;
  right: -50000px;
flex-direction: column;
align-items: center;
justify-content: center;
margin: auto;
gap:30px;
width: 0;
padding: 10px;
height: 200vw;
background: #fff;
transition: .5s all ease-in-out;

}
.linkside{
  font-weight: 500;
  color:black;
  text-decoration: none;
  align-self: flex-start;
  
position: relative;
top:-150px;
transition: .5s all ease-in-out;

}
.linkside a::after{
content:'';
position: absolute;
width: 0;
height: 4px;
background:#e2af22;
bottom: 0;
margin-top:3px ;
left: 0;
transition: 1.3s all ease-in-out;

}
.linkside a:hover::after{
content:'';
position: absolute;
width: 100%;
height: 4px;
background:#e2b522;
bottom: 0;
left: 0;
}
.linko{
position:relative;
right: 0;
top: 0;
display: flex;
width:60%;
transition: .5s all ease-in-out;
z-index: 3;
margin: auto;
position: fixed;
}

}
</style>
