import { createApp } from 'vue'
import App from './App.vue'
import 'aos/dist/aos.css'
import router from './router'
import  'vue-animejs';
import { createI18n } from 'vue-i18n'


const messages = {
  en: require('./languages/english.json'),
  fr: require('./languages/french.json')
};

const i18n = createI18n({
  locale: 'en', 
  messages,
});

createApp(App).use(router).use(i18n).mount('#app');




