
<template>
    <div class="home">
        <h1 class="title">{{$t('training-page.home.title')}}</h1>
        </div>
        <div class="body">
                <div class="introduction" data-aos="fade-left">

                  
                            <img src="../assets/logisticsTraining.webp" alt="truck" class="inner-image">
                    
                    <div class="text">
                        <h1 class="introduction-title">{{$t('training-page.home.introduction.title')}}</h1>
                        <p class="paragraph">{{$t('training-page.home.introduction.text[0]')}}
                        </p>
                        <p class="paragraph">{{$t('training-page.home.introduction.text[1]')}}
                        </p>
                        <p class="paragraph">{{$t('training-page.home.introduction.text[2]')}}
                        </p>

                    </div>


                   
                </div>
                <div class="expected-outcomes">
                        <div class="expected-outcomes-header-container" data-aos="fade-left">
                                <h1>{{$t('training-page.expected-outcomes.header.title')}}</h1>
                                <p>{{$t('training-page.expected-outcomes.header.description')}}</p>
                        <router-link to="/contact" class="contacts on">{{$t('training-page.expected-outcomes.header.contact-us')}}</router-link>

                        </div>
                        <div class="expected-outcomes-body" data-aos="fade-right">
                            <div class="point">
                                <img src="../assets/identify.png" alt="identify" class="icon">
                                <h1 class="point-title">{{$t('training-page.expected-outcomes.points[0].title')}}</h1>
                                <p class="point-paragraph">{{$t('training-page.expected-outcomes.points[0].paragraph')}}</p>
                            </div>
                            <div class="point">
                                <img src="../assets/predict.png" alt="predict icon" class="icon">
                                <h1 class="point-title">{{$t('training-page.expected-outcomes.points[1].title')}}</h1>
                                <p class="point-paragraph">{{$t('training-page.expected-outcomes.points[1].paragraph')}} 
                                </p>
                            </div>
                           
                            <div class="point">
                                <img src="../assets/decide.png" alt="decide icon" class="icon">
                                <h1 class="point-title">{{$t('training-page.expected-outcomes.points[2].title')}}</h1>
                                <p class="point-paragraph">{{$t('training-page.expected-outcomes.points[2].paragraph')}}
                                </p>
                            </div>
                            <div class="point">
                                <img src="../assets/execute.png" alt="" class="icon">
                                <h1 class="point-title">{{$t('training-page.expected-outcomes.points[3].title')}}</h1>
                                <p class="point-paragraph">{{$t('training-page.expected-outcomes.points[3].paragraph')}}
                                </p>
                            </div>
                        <router-link to="/contact" class="contacts off">{{$t('training-page.expected-outcomes.header.contact-us')}}</router-link>

                           

                        </div>
                </div>
                <div class="driving-techniques">
                    <h1>{{$t('training-page.driving-techniques.title')}}
                    </h1>
                    <p>{{$t('training-page.driving-techniques.description')}}
                    </p>
                 <div class="techniques">
                    <div class="technique" data-aos="fade-left">
                        <p><span>{{$t('training-page.driving-techniques.techniques[0].number')}}</span>{{$t('training-page.driving-techniques.techniques[0].point')}} 
                        </p>
                    </div>
                    <div class="technique" data-aos="fade-left">
                        <p><span>{{$t('training-page.driving-techniques.techniques[1].number')}}</span>{{$t('training-page.driving-techniques.techniques[1].point')}}
 
                        </p>
                    </div>
                    <div class="technique" data-aos="fade-left">
                        <p><span>{{$t('training-page.driving-techniques.techniques[2].number')}}</span> {{$t('training-page.driving-techniques.techniques[2].point')}}
                        </p>
                    </div>
                    <div class="technique" data-aos="fade-left">
                        <p><span>{{$t('training-page.driving-techniques.techniques[3].number')}}</span> {{$t('training-page.driving-techniques.techniques[3].point')}}
                        </p>
                    </div>
                    <div class="technique" data-aos="fade-left">
                        <p><span>{{$t('training-page.driving-techniques.techniques[4].number')}}</span>{{$t('training-page.driving-techniques.techniques[4].point')}}
                        </p>
                    </div>
                    <div class="technique" data-aos="fade-left">
                        <p><span>{{$t('training-page.driving-techniques.techniques[5].number')}}</span> {{$t('training-page.driving-techniques.techniques[5].point')}}
                        </p>
                    </div>
                    <div class="technique" data-aos="fade-left">
                        <p><span>{{$t('training-page.driving-techniques.techniques[6].number')}}</span>{{$t('training-page.driving-techniques.techniques[6].point')}}
                        </p>
                    </div>
                 </div>

                </div>
                <div class="end-of-line">
                    <h1>" {{$t('training-page.end-of-line')}} "</h1>
                </div>
        </div>


</template>
<style scoped>
.home{
    background:url("../assets/truck-moving-front.webp") center center no-repeat;
    background-size: cover;    
    height:50vh;
    width:100vw;
    
    display: flex;
    justify-content: center;
    align-items: center;
    position:relative;
    }
    .home::before{
        content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
    }
    
    .title{
    text-transform: uppercase;
    color: white;
    font-size: 45px;
    position:relative;
    }
    .title::after{
    content: "";
    position: absolute;
    background:#22C0E2;
    width:100%;
    height: 3px;
    bottom: 0;
    left:0;
    }
.body{
    height:321vh;
    font-family: "Montserrat", sans-serif;

}
.introduction{
    display: flex;
    align-items: center;
    justify-content: center;
    width:80vw;
    margin:auto;
    position:relative;
    top:80px;
    gap:30px;
}
.introduction>*{
    width:50%;
}
.introduction>img{
    height: 25%;
}
.introduction .text{
    text-align: left;
    font-size:20px;
    line-height: 2rem;
    font-weight: 400;
}
.introduction-title{
    font-size: 45px;
    text-transform: uppercase;
    font-weight: 800;
}
.contacts{
    color:#fff;
    background:rgba(10, 45, 97, 0.829);
    padding:25px;
    text-decoration: none;
    position:relative;
    top:60px;
}
.expected-outcomes{
    background:#E8E8E8;
    height:86vh;
    margin-top:150px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}
.expected-outcomes-header-container>h1{
    text-transform: uppercase;
    font-size: 40px;
    width: 500px;
}
.expected-outcomes-body{
    display: grid;
    grid-template-columns: repeat(2,300px);
    column-gap: 50px;

}
.point>p{
    text-align: left;
    font-size: 16.5px;
}
.driving-techniques{
    height: 70vh;

}
.driving-techniques>h1{
    font-size: 50px;
}
.driving-techniques>p{
    width: 700px;
    font-size: 18px;
    line-height: 2.5rem;
    margin: auto;
}
.techniques{
    width: 1200px;
    margin: auto;
    height: 300px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.technique{
    display: flex;
 flex-direction:column;
 padding:20px;
 background: #E8E8E8;
 border-radius: 10px;
 width: 200px;
}
.technique>p{
    display: flex;
    flex-direction: column;
    font-size: 16.5px;
}
.technique>p>span{
    font-weight: 500;
    font-size: 14px;

    margin-bottom:30px;
}
.end-of-line{

    background-image: url("../assets/truck-back-header.webp") ;
    background-size:cover;
    height: 250px;
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.end-of-line>h1{
    margin: auto;
    font-size: 50px;
    text-transform: uppercase;
    color: whitesmoke;
}
.off{
    display: none;
}
@media screen and (min-width: 320px) and (max-width: 767px)  {
    .home{
        background-size: cover;    
        height:50vh;
        width:100vw;
        
        display: flex;
        justify-content: center;
        align-items: center;
        }
        
        .title{
        text-transform: uppercase;
        color: white;
        font-size: 45px;
        width:300px;
        position:relative;
        }
    .on{
    display: none;

    }
    .off{
        display:block;
    }
    .body{
        height: 530vh;
    }
.introduction>img{
    display:none;
}
.introduction{
 
    width:90vw;
    margin:auto;
    position:relative;
    top:80px;
}
.introduction>*{
    width:100%;
}

.introduction .text{
    text-align: left;
    font-size:18.5px;
    line-height: 2rem;
    font-weight: 400;
}
.introduction-title{
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 800;
}
.expected-outcomes{
    background:#E8E8E8;
    height:250vh;
    margin-top:150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
}
.expected-outcomes-header-container>h1{
    text-transform: uppercase;
    font-size: 45px;
    text-align: left;
    width: 300px;
    margin:auto;
}

.expected-outcomes-header-container>p{
font-size:18px;
text-align: left;
width:300px;
margin:auto

}
.expected-outcomes-body{
    display: grid;
    grid-template-columns: repeat(1,500px);
    row-gap: 30px;

}
.point>p{
    text-align: left;
    font-size: 18.5px;
    width: 300px;
    margin:auto;
}
.driving-techniques{
    height: 115vh;
    margin-top:100px;
    width:100%;
}
.driving-techniques>h1{
    font-size: 50px;
}
.driving-techniques>p{
    width: 600px;
    font-size: 18px;
    line-height: 2.5rem;
    margin: auto;
}
.techniques{
    width: 400px;
    margin: auto;
    height: 300px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.technique{
    display: flex;
 flex-direction:column;
 padding:10px;
 background: #E8E8E8;
 border-radius: 10px;
 width: 150px;
}
.technique>p{
    
    font-size: 16.5px;
}
.technique>p>span{
    font-size: 12px;

    margin-bottom:20px;
}
.end-of-line{

    background-image: url("../assets/truck-back-header.webp") ;
    background-size:cover;
    height: 400px;
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.end-of-line>h1{
    margin: auto;
    font-size: 40px;
    text-transform: uppercase;
   color: rgba(255, 255, 255, 0.829);
   position:absolute;
   -webkit-text-stroke: 1px rgb(136, 133, 133) thin;
   background:rgba(10, 45, 97, 0.829);
}

}
</style>


<script>
// @ is an alias to /src
import { onMounted } from '@vue/runtime-core'
import AOS from "aos";
import anime from 'animejs'


export default {
  name: 'HomeView',
  components: {
  },
  methods:{
    onEnt(){
      

    }
  },
  setup(){
   let years=document.getElementsByClassName(".years")
   let customers=document.getElementsByClassName(".customers")
   let load=document.getElementsByClassName(".load")

   let seekProgressEl = document.querySelector(".counter");

  onMounted(() => {
    AOS.init(
     
    );
    let animation=anime(
      {
        targets:".years",
        innerHTML:[0 , 23],
        round: 1,
     easing: 'easeInOutExpo',
     duration:2000,
     autoplay:false,
     loop:false
      },
       
     
      
      )
      let load=anime({
 
        targets:".customer",
        innerHTML:[0 , 50],
        round: 1,
     easing: 'easeInOutExpo',
     duration:2000,
     autoplay:false
   
      
      })
      let customer=anime({
   
        targets:".load",
        innerHTML:[0 ,30000],
        round: 1,
     easing: 'easeInOutExpo',
     duration:2000,
     autoplay:false
   
      
      })
      
      
      function scroll() {
       let page=window.pageYOffset
        if(page>=1000){

          animation.play()
          load.play()
          customer.play()
          
        }

};
        window.onscroll =scroll 
  
})
return{
  years,customers,load
}
  },
  
}
</script>