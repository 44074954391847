<template>
    <div class="container">
        <header class="header">
            <nav class="breadcrumb">
                <router-link to="/companyPolicies">{{$t('assetAndInventoryManagementPolicy.header.breadcrumb.companyPoliciesLink')}}</router-link>
                <span>{{$t('assetAndInventoryManagementPolicy.header.breadcrumb.readTime')}}</span>
            </nav>
            <h1>{{$t('assetAndInventoryManagementPolicy.header.title')}}</h1>
            <p class="subheading">{{$t('assetAndInventoryManagementPolicy.header.subheading')}}</p>
        </header>
    
        <main class="main-content">
            <div class="articles">
                <article class="content">
                    <h2>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[0].title')}}</h2>
                    <p>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[0].content')}}</p>
                </article>
                <article class="content">
                    <h2>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[1].title')}}</h2>
                    <p>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[1].content')}}</p>
                </article>
                <article class="content">
                    <h2>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[2].title')}}</h2>
                    <p>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[2].content')}}</p>
                </article>
                <article class="content">
                    <h2>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[3].title')}}</h2>
                    <p>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[3].content[0]')}}</p>
                    <p>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[3].content[1]')}}</p>
                </article>
                <article class="content">
                    <h2>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[4].title')}}</h2>
                    <p>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[4].content[0]')}}</p>
                    <p>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[4].content[1]')}}</p>
                </article>
                <article class="content">
                    <h2>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[5].title')}}</h2>
                    <ul>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[5].list[0]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[5].list[1]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[5].list[2]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[5].list[3]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[5].list[4]')}}</li>
                    </ul>
                </article>
                <article class="content">
                    <h2>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[6].title')}}</h2>
                    <ul>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[6].list[0]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[6].list[1]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[6].list[2]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[6].list[3]')}}</li>
                    </ul>
                </article>
                <article class="content">
                    <h2>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[7].title')}}</h2>
                    <ul>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[7].list[0]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[7].list[1]')}}</li>
                            <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[7].list[2]')}}</li>
                    </ul>
                </article>
                <article class="content">
                    <h2>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[8].title')}}</h2>
                    <ul>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[8].list[0]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[8].list[1]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[8].list[2]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[8].list[3]')}}</li>


                    </ul>
                </article>
                <article class="content">
                    <h2>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[9].title')}}</h2>
                    <ul>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[9].list[0]')}}</li>
                        <li>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[9].list[1]')}}</li>
                    


                    </ul>
                </article>
                <article class="content">
                    <h2>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[10].title')}} {{$t('assetAndInventoryManagementPolicy.mainContent.articles[10].content')}}</h2>
                    <p>{{$t('assetAndInventoryManagementPolicy.mainContent.articles[10].closingStatement')}}</p>
                </article>
            </div>
           
        </main>
    </div>
    
</template>

<script setup>

</script>

<style  scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:"poppins";

}

body {
    background-color: #f8f9fa;
    color: #333;
}
.alpha{
    list-style-type:lower-latin;
}
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}
.subList{
    margin:0px 40px;
}
.header {
    text-align: center;
    padding: 40px 20px;
    background-color: #f0f4ff;
    margin-bottom: 40px;
}

.breadcrumb {
    font-size: 0.9rem;
    color: #5f6368;
    margin-bottom: 20px;
}

.breadcrumb a {
    color: #0073e6;
    text-decoration: none;
}

.breadcrumb a:hover {
    text-decoration: underline;
}
.articles{
    width:100vw;
}
article{
    width:100%;
    margin: 50px 0;

}
span{
    font-weight:bold;
}
h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
    font-family:"poppins";
    font-weight:700;
}

.subheading {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto 20px;
}

.stats {
    font-size: 0.9rem;
    color: #777;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.main-content {
    display: flex;
}

.content {
    width: 95%;
    line-height: 1.6;
    text-align:left;
}

.content h2 {
    font-size: 1.9rem;
    margin-bottom: 20px;
    font-family:"poppins";
    font-weight:700;
}
.content p,.content li{
    font-size:19px;
    color:#333E49;
    font-weight: 400;
    margin:20px 0;

}

.sidebar {
    width: 30%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-height: 50px;

}

.download-btn {
    display: inline-block;
    background-color: #00bfa5;
    color: white;
    text-align: center;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 4px;
    margin-bottom: 30px;
}

.download-btn:hover {
    background-color: #009688;
}

.sidebar h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
}

.sidebar ul {
    list-style: none;
    margin-bottom: 20px;
}

.sidebar ul li {
    margin-bottom: 10px;
}

.sidebar ul li a {
    text-decoration: none;
    color: #0073e6;
}

.sidebar ul li a:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
    .main-content {
        flex-direction: column;
    }

    .content, .sidebar {
        width: 100%;
    }

    .header {
        padding: 20px 10px;
    }
}

</style>