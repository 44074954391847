<template>
<div class="home">
<h1 class="title">{{$t('expertise-page.home.title')}}</h1>
</div>
<div class="body">
<div class="exp">

<div class="group-div heavy" data-aos="fade-left">
        <p  class="heavy-vehicle-operations tit"> {{$t('expertise-page.body.exp.heavy.tit')}}
</p>
        <div class="txt">
        <p class="trucks-b"><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.exp.heavy.txt[0]')}}
</p>
        <p class="intercity-buses"><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.exp.heavy.txt[1]')}}
</p>
       <p class="intercity-buses"><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.exp.heavy.txt[2]')}}

</p>
        
        
        </div>
      </div>
       
         <div class="group-div" data-aos="fade-left">
        <div class="storage-and-transportation tit">{{$t('expertise-page.body.storage-and-transportation.tit')}} 
</div>
        <div class="txt">
        <p class="safety-and-regulatory-complian"><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.storage-and-transportation.txt[0]')}}</p >
        <p class="design-warehouse-layout-to-inc" ><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.storage-and-transportation.txt[1]')}}</p>
        <p class="training-and-certification-of"><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.storage-and-transportation.txt[2]')}}
</p >
        <p class="design-and-implementation-of-t"><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.storage-and-transportation.txt[3]')}}
</p>
        
        </div>

      </div>
      <div class="group-div drive" data-aos="fade-left">
        
        <p class="training-and-development-of-lo tit" >{{$t('expertise-page.body.drive.tit')}}
</p>
        <div class="txt">
        
        <p class="drivers-b driver"><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.drive.txt[0]')}}</p>
        <p class="road-patrollers-b"><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.drive.txt[1]')}}s</p>
        <p class="transportation-staff-b"><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.drive.txt[2]')}} </p>
        <p class="transportation-staff-b"><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.drive.txt[3]')}} </p>
                <p class="transportation-staff-b"><i class="fa-solid fa-feather-pointed"></i> {{$t('expertise-page.body.drive.txt[4]')}} </p>



      </div>
        
        </div>

</div>


</div>
</template>
<script>
import { onMounted } from '@vue/runtime-core';
export default ({
    setup() {

    },
})
</script>
<style scoped>
.tit{
font-size: 24px;
font-weight: 500;
position: relative;
width:60%;
margin: auto;


}
.txt>p{
font-weight: 500;
text-align: left;
font-size: 18px;
width:50%;
}
.txt{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap:5px;
text-align: left;
}
.tit::after{
content:"";
position:absolute;
width:100%;
background: #FF9F0F;
height:3px;
margin:auto;
bottom: 0;
left: 0 ;}
.HWDI{
display: flex;
flex-wrap: wrap;
gap: 30px;
align-items: center;
justify-content: center;
margin: auto;
}
.HWDI>*{
text-align: left;

}
.body{
height:100%;
 padding:20px;
}
.group-div{
width: 60%;
height: 43%;
padding:15px;
background: #F0F0F0;
box-shadow: 0px 4px 10px 6px rgba(212, 211, 211, 0.671);
margin:auto;
transition: .4s all ease-in-out;
}

.group-div:hover{

box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.25);
}
.exp{
display:flex;
flex-direction: column;
gap: 30px;
margin-top: 50px;
}
.home{
background-image: url("../assets/rectangle-31@2x.webp");
background-position: center center;
background-size: cover;
background-repeat: no-repeat;
height: 50vh;
width: 100vw;
display:flex;
align-items: center;
justify-content: center;


}
.title{
font-size: 45px;
color:white;
text-transform: uppercase;
position: relative;
}
.title::after{
content: " ";
background:#FF9F0F;
width: 100%;
height:3px;
bottom: 0;
left:0;

position:absolute;
}

@media screen and (max-width: 1300px) {

.title{
font-size: 30px;
}
.group-div{
width: 80%;
height: 30vh;
background: #F0F0F0;
box-shadow: 0px 4px 10px 6px rgba(212, 211, 211, 0.671);
margin:auto;
transition: 1s all ease-in-out;
}
.txt{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap:2px;
text-align: left;
}
.txt>p{
font-weight: 400;
text-align: left;
font-size: 14px;
}


.heavy{
  height: 15vh;
}
}
@media screen and (min-width: 768px) and (max-width: 1023px) {

.title{
font-size: 30px;
}
.group-div{
width: 100%;
height: 45vh;
background: #F0F0F0;
box-shadow: 0px 4px 10px 6px rgba(212, 211, 211, 0.671);
margin:auto;
transition: 1s all ease-in-out;
}
.txt{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap:5px;
text-align: left;
}
.txt>p{
font-weight: 500;
text-align: left;
font-size: 16px;
}

.heavy{
  height: 38vh;
}
.drive{
  height:42vh;
}
.tit{
width:80%;
font-size: 19px;
font-weight: 400;
}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
.title{
font-size: 30px;
}
.group-div{
width: 100%;
height: 50%;
background: #F0F0F0;
box-shadow: 0px 4px 10px 6px rgba(212, 211, 211, 0.671);
margin:auto;
transition: 1s all ease-in-out;
}
.txt{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap:5px;
text-align: left;
}
.txt>p{
font-weight: 400;
text-align: left;
font-size: 13px;
}


}


</style>
