<template>
    <div class="page">
        <div class="home">
          <div class="title">{{$t('page.home.title')}}</div>
        </div>
        <div class="bod">
          <div class="op_freight_management">
            <h1>{{$t('page.bod.op_freight_management.title')}}</h1>
            <p class="OFM">{{$t('page.bod.op_freight_management.OFM')}}</p>
            <div class="fleet flat">
              <div class="benefit">
                <div class="ben last">
                  <h2>{{$t('page.bod.op_freight_management.fleet.benefit.0.title')}}</h2>
                  <p>{{$t('page.bod.op_freight_management.fleet.benefit.0.description')}}</p>
                </div>
                <div class="ben">
                  <h2>{{$t('page.bod.op_freight_management.fleet.benefit.1.title')}}</h2>
                  <p>{{$t('page.bod.op_freight_management.fleet.benefit.1.description')}}</p>
                </div>
                <div class="ben">
                  <h2>{{$t('page.bod.op_freight_management.fleet.benefit.2.title')}}</h2>
                  <p>{{$t('page.bod.op_freight_management.fleet.benefit.2.description')}}</p>
                </div>
                <div class="ben">
                  <h2>{{$t('page.bod.op_freight_management.fleet.benefit.3.title')}}</h2>
                  <p>{{$t('page.bod.op_freight_management.fleet.benefit.3.description')}}</p>
                </div>
                <div class="ben">
                  <h2>{{$t('page.bod.op_freight_management.fleet.benefit.4.title')}}</h2>
                  <p>{{$t('page.bod.op_freight_management.fleet.benefit.4.description')}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="op_fleet_management">
            <div class="title-desccription" data-aos="fade-left">
              <h1>{{$t('page.bod.op_fleet_management.title-desccription.title')}}</h1>
              <p>{{$t('page.bod.op_fleet_management.title-desccription.description')}}</p>
            </div>
            <div class="fleet flat-fleet" data-aos="fade-right">
              <div class="benefits">
                <div class="beni">
                  <h2>{{$t('page.bod.op_fleet_management.fleet.benefits.0.title')}}</h2>
                  <p>{{$t('page.bod.op_fleet_management.fleet.benefits.0.description')}}</p>
                </div>
                <div class="beni">
                  <h2>{{$t('page.bod.op_fleet_management.fleet.benefits.1.title')}}</h2>
                  <p>{{$t('page.bod.op_fleet_management.fleet.benefits.1.description')}}</p>
                </div>
                <div class="beni">
                  <h2>{{$t('page.bod.op_fleet_management.fleet.benefits.2.title')}}</h2>
                  <p>{{$t('page.bod.op_fleet_management.fleet.benefits.2.description')}}</p>
                </div>
                <div class="beni">
                  <h2>{{$t('page.bod.op_fleet_management.fleet.benefits.3.title')}}</h2>
                  <p>{{$t('page.bod.op_fleet_management.fleet.benefits.3.description')}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      


</template>
<script>
export default {
    components:{
    },

    setup() {
        
    },
}
</script>
<style scoped>
.home{
background:url("../assets/rectangle-30@2x.webp") center center;
background-attachment: scroll;


height:50vh;
width:100vw;

display: flex;
justify-content: center;
align-items: center;
}

.title{
text-transform: uppercase;
color: white;
font-size: 45px;
position:relative;
}
.title::after{
content: "";
position: absolute;
background:#22C0E2;
width:100%;
height: 3px;
bottom: 0;
left:0;
}
.op_freight_management>h1{
    font-family:'poppins', Times, serif;

text-transform: uppercase;
font-weight: 800;
font-size: 55px;
color:#222222;
}
.title-desccription{
    align-self: flex-start;
}
.title-desccription>p{
}
.title-desccription>h1{
    text-transform: uppercase;
    font-weight: 800;
    font-size: 55px;
    font-family:'Times New Roman', Times, serif;
    text-align: left;
    color:#222222;
    width:80%;margin-left:90px;
    }
.op_freight_management{
background: url("../assets/akshay-nanavati-8FevUlxdZC0-unsplash.webp") no-repeat center ;
backdrop-filter: brightness(120%);
background-size: cover;
padding: 20px;
height:180vh;

}
.op_fleet_management{
    height:90vh;
    padding:100px;
    background-size: cover;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    gap:20px;
}
.ben{
background:rgba(243, 243, 243, 0.363);
padding: 10px;
transition:.3s all ease-in-out;
border-radius: 5px;
}
.ben:hover{
    background:rgba(243, 243, 243, 0.932);

}
.ben>p{
    font-size: 18px;
     font-weight: 400;
     line-height: 2;
    transition:.3s all ease-in-out;
    
}

.ben:hover>p{
    color:#000;
}
.ben>h2{
text-transform: capitalize;
font-size: 20px;
transition:.3s all ease-in-out;
position: relative;
width: fit-content;

}
.ben:hover>h2{
    color:#000;

}
.beni{
           background:rgba(207, 207, 207, 0.534);

    padding: 15px;
    transition:.3s all ease-in-out;
    border-radius: 5px;
    }
    .beni:hover{
        background:rgba(243, 243, 243, 0.932);
    
    }
    .beni>p{
        font-size: 16px;
         font-weight: 500;
         line-height: 2.8;
        transition:.3s all ease-in-out;
        text-transform: capitalize;
        text-align: left;
        
    }
    
    .beni:hover>p{
        color:#000;
    }
    .beni>h2{
    text-transform: capitalize;
    font-size: 20px;
    transition:.3s all ease-in-out;
    position: relative;
    width: fit-content;
    text-align: center;
    margin:auto;
    }
    .beni:hover>h2{
        color:#000;
       
    
    }
.OFM{
font-weight:600;
font-size:18px;
color:#222222;
text-transform: capitalize;
width:650px;
margin:auto;
text-align:left;
line-height: 2;
}
.ben>h2::after{
    content: " ";
    position: absolute;
    height: 2px;
    background: #FF9F0F;
    width:0px;
    bottom: 0;
    left:0;
    
transition:.3s all ease-in-out;
    
    }
    .ben:hover>h2::after{
        content: "";
            width:60%;
            }
 .beni:hover>h2::after{
    content: "";
        width:60%;
        }
        .beni>h2::after{
            content: " ";
            position: absolute;
            height: 2px;
            background: #FF9F0F;
            width:0px;
            bottom: 0;
            right:60px;
            
        transition:.3s all ease-in-out;
            
            }
         .beni:hover>h2::after{
            content: "";
                width:60%;
                }
        
.fleet{
display:flex;
align-items: center;
justify-content: center;
}


.benefit{
display: grid;
grid-template-columns:repeat(2  ,400px) ;
grid-auto-rows: minmax(270px,auto);
grid-row-gap: 10px;
grid-column-gap: 10px;
}
.last{
grid-column-start:1 ;
grid-column-end:-1;
}
.benefits{
    display: grid;
    grid-template-columns:repeat(2  ,300px) ;
    grid-auto-rows: minmax(270px,auto);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        .OFM{
            font-weight:500;
            font-size:16px;
            color:#ffffff;
            text-transform: capitalize;
            width:600px;
            margin:auto;
            line-height: 3;
            }
            .ben{
                background:rgba(243, 243, 243, 0.815);
                padding: 10px;
                transition:.3s all ease-in-out;
                border-radius: 5px;
                }
        .benefits{
            display: grid;
            grid-template-columns:repeat(2  ,400px) ;
            grid-auto-rows: minmax(250px,auto);
            grid-row-gap: 10px;
            grid-column-gap: 10px;
            }
            .op_fleet_management{
                height:130vh;
                padding:50px;
                background: url("../assets/blob-scene-haikei.svg") no-repeat center;
                background-size: cover;
                flex-direction: column;
            }
    
    }
    @media screen and (min-width: 320px) and (max-width: 767px)  {

        .fleet{
            align-items: flex-start;
            justify-content:flex-end;
        }
        .flat{
            align-items: center;
            justify-content: center;
            gap:40px;
}
.flat-fleet{
    margin-left:-30px;

}
        .benefits{
            display: grid;
            grid-template-columns:repeat(1  ,330px) ;
            grid-auto-rows: minmax(150px,auto);
            grid-row-gap: 10px;
            grid-column-gap: 10px;
            }
        .op_freight_management>h1{
            
              text-transform: uppercase;
            font-weight: 800;
            font-size: 45px;
            font-family:'poppins', Times, serif;
            text-align: center;
            color:#222222;
            width:100%;
            margin:auto;
            color:#222222;
            }
    
        .op_freight_management{
        height:200vh;
        }
        .title-desccription{
        }
        .title-desccription>p{
            font-weight:600;
                    font-size:17px;
                    color:#222222;
                    text-transform: capitalize;
                    width:300px;
                   text-align: left;
                    line-height: 2;
            margin-left:-20px;
        }
        .title-desccription>h1{
            text-transform: uppercase;
            font-weight: 800;
            font-size: 45px;
            font-family:'poppins', Times, serif;
            text-align: left;
            color:#222222;
            width:100%;
            margin-left:-20px;
            }
        
        .benefit{
            display: grid;
            grid-template-columns:repeat(1  ,330px) ;
            grid-auto-rows: minmax(170px,auto);
            grid-row-gap: 5px;
            grid-column-gap: 5px;
            }
          
            .ben{
                background:rgba(243, 243, 243, 0.89);
                padding: 20px;
                transition:.3s all ease-in-out;
                border-radius: 5px;
                }
              
                .ben>p{
                    font-size: 15px;
                    text-align: left;
                     font-weight: 500;
                     line-height: 2;
                    transition:.3s all ease-in-out;
                    
                }
                
              
                .ben>h2{
                text-transform: capitalize;
                font-size: 18px;
                transition:.3s all ease-in-out;
                position: relative;
                width: fit-content;
                
                }
                .OFM{
                    font-size:17px;
                    color:#222222;
                    text-transform: capitalize;
                    width:450px;
                   padding:20px;
                    line-height: 2;
            margin:auto;
                    }
                    .op_fleet_management{
                        height:200vh;
                        padding:50px;
                        background: url("../assets/blob-scene-haikei.svg") no-repeat center;
                        background-size: cover;
                        flex-direction: column ;
                        width:100vw;
                        align-items:center;
                        justify-content:center;
                        margin:auto;
                    }
                    .beni{
                        background:rgba(172, 170, 170, 0.295);
                        padding: 10px;
                        transition:.3s all ease-in-out;
                        border-radius: 5px;
                        height:200px;
                        }
                        .beni>p{
                            font-size: 16.5px;
                             font-weight: 500;
                             line-height: 2;
                            transition:.3s all ease-in-out;
                            text-transform: capitalize;
                            text-align:left;
                            
                        }
                        
    }
</style>