<template>
    <div class="home">
        <h1 class="title">{{$t('policyLinks.title')}}</h1>
        </div>
    <section>

        <ul>
            <li>
                <router-link :to="{name: 'FinancialManagementPolicy'}">
                    {{$t('policyLinks.links[0].name')}}
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'AssetAndInventoryManagementPolicy'}">
                    {{$t('policyLinks.links[1].name')}}
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'confidentialityAndDataProtectionPolicy'}">
                    {{$t('policyLinks.links[2].name')}}
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'EnvironmentalManagementPolicy'}">
                    {{$t('policyLinks.links[3].name')}}
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'moniteringPolicy'}">
                    {{$t('policyLinks.links[4].name')}}
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'HRPolicy'}">
                    {{$t('policyLinks.links[5].name')}}
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'PartnershipPolicy'}">
                    {{$t('policyLinks.links[6].name')}}
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'GovernancePolicy'}">
                    {{$t('policyLinks.links[7].name')}}
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'ProgrammaticAccountabilityPolicy'}">
                    {{$t('policyLinks.links[8].name')}}
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'RiskManagementPolicy'}">
                    {{$t('policyLinks.links[9].name')}}
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'ProcurementManagementPolicy'}">
                    {{$t('policyLinks.links[10].name')}}
                </router-link>
            </li>
        </ul>
    </section>
    
    
</template>

<script setup>

</script>

<style  scoped>
.home{
    background-image: url("../assets/rectangle-31@2x.webp");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50vh;
    width: 100vw;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    
    }
    .title{
        font-size: 45px;
        color:white;
        text-transform: uppercase;
        position: relative;
        }
        .title::after{
        content: " ";
        background:#FF9F0F;
        width: 100%;
        height:3px;
        bottom: 0;
        left:0;
        
        position:absolute;
        }
section{
        height:70vh;
        font-family: "poppins";
    }
    a{
        color:black;
        text-decoration: none   ;
        position:relative;
    }
     a::after{
        content:'';
        position: absolute;
        width: 0;
        height: 2px;
        background:#fcbc0d;
        bottom: 0;
        margin-top:3px ;
        left: 0;
        transition: .3s all ease-in-out;
        
        }
        a:hover::after{
        content:'';
        position: absolute;
        width: 100%;
        height: 2px;
        background:#fcbc0d;
        bottom: 0;
        left: 0;
        }
    ul{
        font-family:"poppins";
        display:flex;
        align-items:center;
        justify-content:center;
        width:60vw;
        height:50vh;
        gap:20px;
        flex-wrap:wrap;

        margin:auto;


    }
    li{
            text-transform: capitalize;
            flex-basis: 40%;
            text-align: left;
        box-sizing: border-box;
        font-weight: 600;
    }

    @media (max-width: 768px) {

        section{
            flex-direction:column ;
        }
        li{
            flex:1;
            flex-basis: 100%;
        }
    }

</style>